<template>
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M20.576 4l-1.212 1.266c-3.341 3.466-6.906 7.4-10.244 10.915l-4.612-3.93-1.33-1.133L1 13.86l1.32 1.133 5.835 4.974L9.367 21l1.094-1.144c3.696-3.834 7.7-8.319 11.327-12.08L23 6.508 20.576 4z"
      fill-rule="nonzero"
    />
  </svg>
</template>
