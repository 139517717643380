import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FirstRow = _resolveComponent("FirstRow")
  const _component_SecondRow = _resolveComponent("SecondRow")
  const _component_ThirdRow = _resolveComponent("ThirdRow")

  return (_openBlock(), _createBlock("nav", {
    ref: "header",
    class: ["relative z-40 text-sm font-display", _ctx.isBackgroundBlack ? 'bg-amff-black-2024' : '']
  }, [
    _createVNode(_component_FirstRow, { isBackgroundBlack: _ctx.isBackgroundBlack }, null, 8, ["isBackgroundBlack"]),
    _createVNode(_component_SecondRow),
    _createVNode(_component_ThirdRow, { isBackgroundBlack: _ctx.isBackgroundBlack }, null, 8, ["isBackgroundBlack"])
  ], 2))
}