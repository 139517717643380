import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("img", {
      src: `${_ctx.baseURL}img/Logo-AMFF-siglas.png`,
      class: _ctx.isMobile ? 'w-32' : 'w-44'
    }, null, 10, ["src"]),
    _createVNode("img", {
      class: "ml-6",
      src: `${_ctx.baseURL}img/filmin-proyect.png`,
      style: {"width":"auto","height":"45px"}
    }, null, 8, ["src"])
  ]))
}