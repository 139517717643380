
import { defineComponent, computed, onMounted, onUnmounted, ref } from "vue";
import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import { useHeaderLinks } from "@/composables/useHeaderLinks";

export default defineComponent({
  components: { ChevronIcon, AnchorLink },
  props: {
    isBackgroundBlack: { type: Boolean },
  },
  setup() {
    const { items, expandSubitem } = useHeaderLinks();
    const subitems = computed(() => {
      /* tslint:disable-next-line */ return (
        items.value.find((item: any) => item.isExpanded) as any
      )?.children;
    });
    /** close dropdowns when clicking anywhere on the body */
    const expandedSubitem = computed(() =>
      subitems.value?.find((item: any) => item.isExpanded)
    ) as any;
    const unselectDropdownItem = () => {
      if (expandedSubitem.value && expandedSubitem.value?.children?.length) {
        expandSubitem(null);
      }

      langDropdownOpen.value = false;
    };

    const langDropdownOpen = ref(false);

    onMounted(() => {
      window.addEventListener("click", unselectDropdownItem, { capture: true });
    });

    onUnmounted(() => {
      window.removeEventListener("click", unselectDropdownItem);
    });

    return { subitems, AnchorLink, expandSubitem };
  },
});
