<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="71"
    height="20"
    viewBox="0 0 71 20"
  >
    <title>Filmin</title>

    <path
      d="M48.878 19.25c0 .45.298.75.744.75h4.79c.446 0 .744-.3.744-.75V.75c0-.45-.298-.75-.745-.75h-4.789c-.446 0-.744.3-.744.75v18.5zm7.997 0c0 .45.298.75.746.75h4.327c.448 0 .746-.3.746-.75v-7c0-.125.075-.2.2-.2.173 0 .198.125.322.45l2.363 6.975c.1.3.348.525.72.525h3.955c.447 0 .746-.3.746-.75V.75c0-.45-.299-.75-.746-.75h-4.327c-.448 0-.746.3-.746.75v7c0 .125-.075.2-.2.2-.148 0-.173-.05-.273-.325L62.321.575C62.197.2 61.973 0 61.55 0h-3.93c-.447 0-.745.3-.745.75v18.5zM0 19.25c0 .45.298.75.745.75h4.796c.447 0 .746-.3.746-.75v-6.475c0-.375.198-.575.571-.575h1.938c.448 0 .746-.3.746-.75V9.7c0-.45-.298-.75-.746-.75H6.858c-.373 0-.571-.2-.571-.575V4.45c0-.375.198-.575.571-.575H8.97c.447 0 .746-.3.746-.75V.75C9.716.3 9.417 0 8.97 0H.745C.298 0 0 .3 0 .75v18.5zm10.986 0c0 .45.298.75.745.75h4.789c.447 0 .744-.3.744-.75V.75c0-.45-.297-.75-.744-.75h-4.79c-.446 0-.744.3-.744.75v18.5zm7.997 0c0 .45.324.75.748.75h8.22c.449 0 .748-.3.748-.75v-2.375c0-.425-.299-.75-.747-.75h-2.118c-.374 0-.548-.175-.548-.55V.75c0-.425-.324-.75-.747-.75H19.73c-.424 0-.748.325-.748.75v18.5zm10.688 0c0 .45.298.75.747.75h4.11c.449 0 .748-.3.748-.75v-8.875c0-.3.1-.4.2-.4.124 0 .223.075.273.4l1.62 9.075c.05.3.323.55.672.55h.797c.349 0 .623-.25.673-.55l1.644-9.075c.075-.35.175-.4.3-.4.124 0 .173.1.173.4v8.875c0 .45.3.75.748.75h4.11c.449 0 .748-.3.748-.75V.75c0-.45-.3-.75-.748-.75h-5.83c-.472 0-.697.3-.771.75l-1.171 8.325c-.025.2-.125.3-.275.3-.149 0-.249-.1-.273-.3L37.019.75C36.97.3 36.696 0 36.247 0h-5.829c-.449 0-.747.3-.747.75v18.5z"
      transform="translate(-852 -2611) translate(0 2438) translate(518 173) translate(334)"
    />
  </svg>
</template>
