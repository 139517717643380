
import { defineComponent } from "vue";
// @ts-expect-error error
import TikTokIcon from "@/assets/rrss/tiktok.png";
// @ts-expect-error error
import YoutubeIcon from "@/assets/rrss/youtube.png";
// @ts-expect-error error
import TwitterIcon from "@/assets/rrss/twitter.png";
// @ts-expect-error error
import FacebookIcon from "@/assets/rrss/facebook.png";
// @ts-expect-error error
import InstagramIcon from "@/assets/rrss/instagram.png";
import { useMediaQuery } from "@vueuse/core";

export default defineComponent({
  components: {},
  setup() {
    const isMobile = useMediaQuery("(max-width: 1023px)");

    return {
      tiktokURL: "https://www.tiktok.com/@enfilmin?lang=es",
      youtubeURL: "https://www.youtube.com/@enfilmin",
      twitterURL: "https://twitter.com/atlantidafilm",
      spotifyURL:
        "https://open.spotify.com/playlist/53K37PKWE5W4iF6UyCIqxG?si=70465f2ec3df4b05",
      TikTokIcon,
      YoutubeIcon,
      TwitterIcon,
      FacebookIcon,
      InstagramIcon,
      instagramURL: "https://www.instagram.com/atlantidamallorcafilmfest/",
      facebookURL: "https://www.facebook.com/atlantidamallorcafilmfest/",
      isMobile,
    };
  },
});
