import "focus-visible/dist/focus-visible.min.js";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "@/plugins/i18n";

import "./app.css";

if (window.innerWidth < 450) {
  document
    .querySelector("meta[name=viewport]")
    ?.setAttribute("content", "user-scalable=no, width=450");
}

createApp(App).use(i18n).use(router).mount("#app");
