import { MaybeRef } from "@vueuse/shared";
import { computed, ref, unref } from "vue-demi";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { useRouteWithLang } from "./useRouteWithLang";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useHeaderLinks = () => {
  const { route, currentLang } = useRouteWithLang();
  const currentRouteName = computed(() => route.name);

  const expandedItemId = ref<string | null>(null);
  const expandedSubitemId = ref<string | null>(null);

  const thirdRowRouteNames = [
    "jobs",
    "home",
    "events",
    "event",
    "movies",
    "talks",
    "schedule",
    "accredit",
    "professionals-talents",
    "professionals-eurovod",
    "professionals-partakers",
    //"professionals-stars",
    "mallorca-an-island-of-movie",
    "emerging-workshop",
    "green-film-lab",
    "news",
    "post",
  ];

  const items = computed(() => [
    {
      id: "mallorca",
      text: "header.mallorca",
      ...linkProps(route, { name: "home" }),
      isSelectedMobile:
        route.name === "home" ||
        thirdRowRouteNames.includes(currentRouteName.value as string),
      isExpandedMobile: expandedItemId.value === "mallorca",
      isSelected:
        route.name === "home" ||
        thirdRowRouteNames.includes(currentRouteName.value as string),
      isExpanded: thirdRowRouteNames.includes(currentRouteName.value as string),
      children: [
        {
          id: "mallorca-home",
          text: "header.start",
          ...linkProps(route, { name: "home" }),
          isExpanded: expandedSubitemId.value === "mallorca-home",
        },

        {
          id: "program",
          text: "header.program",
          // isSelected: route.name === "events",
          ...linkProps(route, {
            name: "events",
            params: { type: "movies" },
          }),
          /* This item will not have subitems for the first phase of AMFF site
          isExpanded: expandedSubitemId.value === "program",
          children: [
            {
              text: "header.program_complete",
              ...linkProps(route, { name: "events" }),
              isSelected:
                route.name === "events" && route.params.type === undefined,
            },
            {
              text: "header.movies",
              ...linkProps(route, {
                name: "events",
                params: { type: "movies" },
              }),
              isSelected:
                route.name === "events" && route.params.type === "movies",
            },
            {
              text: "header.music",
              ...linkProps(route, {
                name: "events",
                params: { type: "music" },
              }),
              isSelected:
                route.name === "events" && route.params.type === "music",
            },
            {
              text: "header.talks",
              ...linkProps(route, {
                name: "events",
                params: { type: "talks" },
              }),
              isSelected:
                route.name === "events" && route.params.type === "talks",
            },
          ],
          */
        },
        {
          id: "schedule",
          text: "header.parrilla",
          ...linkProps(route, { name: "schedule" }),
          isExpanded: expandedSubitemId.value === "schedule",
        },
        {
          id: "schedule_program",
          text: "events.download_schedule",
          isExpanded: expandedSubitemId.value === "schedule_program",
          children: [
            {
              text: "Castellano",
              href: "https://issuu.com/filminapp/docs/amff24-programa-es",
              // isSelected:
              //   route.name === "schedule" && route.params.type === undefined,
            },
            {
              text: "Català",
              href: "https://issuu.com/filminapp/docs/amff24-programa-cat",
              // isSelected:
              //   route.name === "schedule" && route.params.type === undefined,
            },
          ],
        },
        {
          id: "accredit",
          text: "events.accredit_yourself",
          isExpanded: expandedSubitemId.value === "accredit",
          children: [
            {
              text: "events.industry",
              href: "https://enfilmin.typeform.com/acreditaciones",
            },
            {
              text: "events.press",
              href: "https://enfilmin.typeform.com/prensaAMFF",
            },
          ],
        },

        // {
        //   text: "header.talents_lab",
        //   ...linkProps(route, {
        //     name: "professionals-talents",
        //     params: {},
        //   }),
        // },

        // {
        //   id: "industry",
        //   text: "Industry",
        //   isExpanded: expandedSubitemId.value === "industry",
        //   isSelected: [
        //     "professionals-talents",
        //     "professionals-eurovod",
        //     "professionals-partakers",
        //     //"professionals-stars",
        //   ].includes(currentRouteName.value as string),
        //   children: [
        //     {
        //       text: "header.mallorca_talents_lab",
        //       ...linkProps(route, {
        //         name: "professionals-talents",
        //         params: {},
        //       }),
        //     },
        //     {
        //       text: "header.mallorca_an_island_of_movie",
        //       ...linkProps(route, {
        //         name: "event",
        //         params: {
        //           type: "conference",
        //           slug: "una-isla-de-pelicula",
        //         },
        //       }),
        //     },
        //     {
        //       text: "header.emerging_workshop",
        //       ...linkProps(route, {
        //         name: "event",
        //         params: {
        //           type: "conference",
        //           slug: "taller-emergente",
        //         },
        //       }),
        //     },
        //     {
        //       text: "header.encuentro_comica",
        //       ...linkProps(route, {
        //         name: "event",
        //         params: {
        //           type: "conference",
        //           slug: "encuentro-comica",
        //         },
        //       }),
        //     },
        //     {
        //       text: "Green Film Lab",
        //       ...linkProps(route, {
        //         name: "event",
        //         params: {
        //           type: "conference",
        //           slug: "green-film-lab",
        //         },
        //       }),
        //     },
        //     // {
        //     //   text: "header.eurovod",
        //     //   ...linkProps(route, { name: "professionals-eurovod" }),
        //     // },
        //     // {
        //     //   text: "header.partakers",
        //     //   ...linkProps(route, { name: "professionals-partakers" }),
        //     // },
        //     /*{
        //       text: "header.screen_stars",
        //       ...linkProps(route, {
        //         name: "professionals-stars",
        //       }),
        //     },*/
        //   ],
        // },
        // {
        //   id: "news",
        //   text: "header.news",
        //   ...linkProps(route, { name: "news" }),
        //   isSelected: route.name === "news" || route.name === "post",
        //   isExpanded: expandedSubitemId.value === "news",
        // },
      ],
    },
    // {
    //   id: "jobs",
    //   text: "header.jobs",
    //   href: "https://enfilmin.typeform.com/trabaja-en-AMFF",
    //   isSelectedMobile: route.name === "jobs",
    // },
    // {
    //   id: "online",
    //   text: "header.online",
    //   href: "https://www.filmin.es/festival/atlantida-film-fest-2022",
    //   //...linkProps(route, { name: "online" }),
    //   isSelectedMobile: route.name === "online",
    // },

    // {
    //   id: "international",
    //   text: "header.international",
    //   ...linkProps(route, { name: "international" }),
    //   isSelectedMobile: route.name === "international",
    // },
  ]);

  const expandItem = (item: null | MaybeRef<{ id: string }>) => {
    if (!item) {
      expandedItemId.value = null;
    } else if (unref(item).id === expandedItemId.value) {
      expandedItemId.value = null;
    } else {
      expandedItemId.value = unref(item).id;
    }
  };

  const expandSubitem = (subitem: null | MaybeRef<{ id: string }>) => {
    if (!subitem) {
      expandedSubitemId.value = null;
    } else {
      expandedSubitemId.value = unref(subitem).id;
    }
  };

  const currentRouteSwitchingLangs = computed(() => ({
    to: route.name,
    params: {
      ...route.params,
      lang: currentLang.value === "es" ? "cat" : "es",
    },
  }));

  const isCurrentLinkActive = (linkName: string): boolean => {
    return currentRouteName.value === linkName;
  };

  return {
    items,
    currentLang,
    currentRouteSwitchingLangs,
    expandItem,
    expandSubitem,
    isCurrentLinkActive,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function linkProps(route: RouteLocationNormalizedLoaded, routerLink: any) {
  return {
    isSelected: route.name === routerLink.name,
    to: {
      ...routerLink,
      params: {
        ...routerLink.params,
        lang: unref(route.params.lang),
      },
    },
  };
}
