
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: { type: Object, required: false },
    href: { type: String, required: false },
  },
  setup() {
    const { buildRouteWithLang } = useRouteWithLang();
    return { buildRouteWithLang };
  },
});
