{
  "languages": {
    "es": "Español",
    "cat": "Català"
  },
  "short_languages": {
    "es": "Español",
    "cat": "Català"
  },
  "header": {
    "mallorca": "Mallorca",
    "mallorca_edition": "19.07 - 28.07 / Mallorca",
    "online_edition": "19.07 - 19.08 / filmin.es",
    "editions": "Ediciones",
    "history": "Historia",
    "team": "Equipo",
    "who_we_are": "Quiénes somos",
    "contact": "Contacto",
    "online": "Online",
    "international": "Internacional",
    "start": "Inicio",
    "jobs": "Trabaja en AMFF",
    "program": "Programa",
    "program_complete": "Programa completo",
    "movies": "Películas",
    "music": "Música",
    "talks": "Talks",
    "parrilla": "Parrilla",
    "professionals": "Profesionales",
    "mallorca_talents_lab": "Mallorca Talents Lab",
    "talents_lab": "Talents lab",
    "partakers": "Invitados",
    "eurovod": "Eurovod",
    "screen_stars": "Screen Starts",
    "news": "Noticias",
    "press": "Prensa",
    "mallorca_an_island_of_movie": "Mallorca, Una isla de Película",
    "encuentro_comica": "Encuentro COMICA",
    "emerging_workshop": "Taller Emergentes"
  },
  "footer": {
    "press_kit": "Kit de prensa",
    "contact": "Contacto",
    "sponsors": "Sponsors",
    "proyect_of": "Atlàntida Mallorca Film Fest es un proyecto de"
  },
  "actions": {
    "share": "Compartir",
    "copy": "Copiar",
    "copied": "Copiado",
    "close": "Cerrar",
    "more_details": "Más info"
  },
  "home": {
    "header_description": "14a edición",
    "header_slogan": "El verano que te cambiará la vida",
    "header_description2": "Una semana en Mallorca, un mes en Filmin",
    "header_description3": "Hasta el 31 de Mayo presenta tu proyecto y consigue que FILMIN participe en la coproducción.",
    "discover_this_years_content": "Más de 80 eventos exclusivos",
    "follow_festival_at": "Sigue el festival con",
    "see_the_full_program": "Ver el programa completo",
    "play_trailer": "Ver tráiler",
    "watch_aftermovie": "Ver resumen",
    "overview_movies": "Más de 100 estrenos exclusivos, arriesgados y provocadores. Desde las grandes triunfadoras de Berlín, Cannes y Sundance que aterrizan por primera vez en España, hasta el debut del talento más rompedor, pasando por las ya míticas secciones LGTBI+, Arts, Clàssics, Balear o Nins. Noé, Huppert, Sachs, Serra, Arana, Abramovic, Jordan, Martín Gimeno, Loznitsa, Padial, Villaronga y muchos más.",
    "overview_music": "Las mejores propuestas emergentes del panorama underground comparten escenario con artistas y bandas consagradas para cerrar las noches de Atlàntida por todo lo alto. Alizzz, Christina Rosenvinge, Natalia Lacunza, Rusoswky, Putochinomaricón, Ladilla Rusa o Rita Payés son algunas de las voces que conforman el sonido de AMFF.  Frescura, petardeo y electropop.",
    "overview_talks": "AMFF es cine, pero también debate, actualidad y política. Parte de la programación irá acompañada de charlas con personalidades de primer nivel para profundizar y reflexionar sobre los temas abordados en las películas que preceden. Pablo Simón, Annie Ernaux, El Barroquista, Slavoj Žižek, Ana Pastor, Pol Guasch, C. Tangana han protagonizado algunas charlas sobre arte, identidad, filosofía, memoria histórica, la crisis climática o las guerras del presente.",
    "featured_events": "Imprescindibles de esta edición",
    "overview_talents_lab": "El punto de encuentro de la industria dentro de Atlàntida. A través de mentorías con algunos de los mejores productores del país, asesoramiento sobre distribución y diseño de audiencias, y la oportunidad de compartir su proyecto ante agentes clave de la industria, AMTL impulsa el talento de autores y productores noveles que prometen ser las grandes voces del futuro del cine nacional.",
    "overview_bono": "Consigue tu pasaporte AMFF-Filmin en Cines Rivoli.",

    "sign_up_title_1": "Sección oficial",
    "sign_up_subtitle_1": "Inscríbete hasta el 16 de Mayo",
    "sign_up_title_2": "Atlàntida Mallorca Talents Lab - WIP",
    "sign_up_subtitle_2": "Inscríbete hasta el 1 de Junio",
    "sign_up_talent": "Inscríbete en Mallorca Talents Lab",
    "sign_up_your_film": "Inscribe tu película en la Sección Oficial",
    "discover_music": "Reserva ya tus entradas",
    "see_the_full_program_23": "Descubre toda la programación",
    "sign_up_film": "INSCRIBE TU PELÍCULA",
    "sub-button_text": "Un nuevo formato para impulsar tu proyecto de largometraje en desarrollo",
    "sub-button_text_2": "Envía tu película terminada y estrénala en el mayor festival híbrido del mundo"
  },
  "post": {
    "share_this_with_your_comunity": "Comparte esto con tu comunidad social",
    "or_copy_link": "o copiar enlace",
    "lastest_news": "Últimas noticias",
    "see_latest_news": "Ver todas las noticias",
    "all_news": "Todas las noticias"
  },
  "error": {
    "are_you_lost": "¿Te has perdido?",
    "calm_down_if_youre_lost": "Nos pasa a todos, no te preocupes. Quizá hayas escrito mal la dirección o el enlace que buscabas ya no existe.",
    "take_me_home": "Llévame al inicio",
    "no_events_in_filter": "No hay ningún evento que coincida con los filtros seleccionados.",
    "try_selecting_a_different_filter": "Prueba seleccionar otro día u otro tipo de evento."
  },
  "coming_soon": {
    "stay_tuned": "Programación completa 1 de Julio.",
    "come_back": "Mil gracias por la paciencia y el interés."
  },
  "events": {
    "download_schedule": "Programa de mano",
    "accredit_yourself": "Acredítate",
    "industry": "Industria",
    "press": "Prensa",
    "event_types": {
      "all": "Todo",
      "movies": "Movies",
      "movie": "Película",
      "music": "Música",
      "talks": "Talks",
      "talk": "Talk"
    },
    "go_to_next_day": "Ir al día siguiente",
    "day": "Día",
    "location": "Localización",
    "section": "Sección",
    "clear_all": "Limpiar todo",
    "buy_tickets": "Comprar entradas",
    "early_access": "Entradas anticipadas agotadas",
    "book_tickets": "Reservar entradas",
    "about_movie": "Sobre la película",
    "the_same_day": "El mismo día",
    "listen_in_spotify": "Escuchar en spotify",
    "original_title": "Título original",
    "tags": "Temas",
    "staff": "Reparto",
    "others_projection": "Otras películas",
    "others_conference": "Otras conferencias",
    "others_concert": "Otros conciertos",
    "full_capacity": "Aforo completo",
    "see_movie": "Ver película",
    "view_all_movies": "Ver todas las películas",
    "coming_soon": "Próximamente"
  },
  "talents": "<p>Atlàntida Mallorca Film Fest, en su duodécima edición, vuelve a apostar por los jóvenes talentos del cine. Con la colaboración de Media y Europa Creativa Catalunya, lanzamos la quinta convocatoria del Mallorca Talents Lab (enadelante, el “Talents Lab”), un espacio que te permitirá desarrollar tu proyecto de largometraje bajo la lupa de losmejores expertos del sector cinematográfico. </p> <p>El  Talents  Lab  tiene  como  objetivo  estrechar  lazos  entre  jóvenes  creadores  y  profesionales  consolidados  quetutorizarán  y  asesorarán  en  el  desarrollo  de  los  proyectos  participantes,  preparándolos  para  acceder  aconvocatorias internacionales y a su paso por festivales.</p> <p>Este año el Talents Lab va más allá de la ficción para acercarse también al documental e incorporar la posibilidadde conseguir el apoyo de Comunidad Filmin, S.L. (“Filmin”), mediante la financiación anticipada de la futura obraaudiovisual, contando con el apoyo y seguimiento de su equipo editorial durante un año.</p>",
  "international": {
    "countries": "Portugal. Grecia. Serbia. Bulgaria. Romanía. Albania.",
    "title": "Edicion Internacional",
    "content": "La IV Edición del Festival online internacional que se llevará a cabo en Portugal Serbia, Grecia, Rumania y Albania se celebrará del 1 de diciembre al 31 de diciembre de 2022. La programación se hará pública en noviembre.",
    "here": "aqui"
  },
  "online": {
    "title": "Online edition",
    "description": "La programación completa del Festival online que se celebrará en Filmin se anunciará el día 8 de julio a las 12h."
  }
}
