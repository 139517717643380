
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import AMFFLogo from "@/components/AMFFLogo.vue";
import MenuIcon from "@/components/icons/MenuIcon.vue";
import MobileAsideHeader from "./MobileAsideHeader.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import { useWindowScroll } from "@vueuse/core";
import { useElementHeight } from "@/composables/useElementHeight";

export default defineComponent({
  components: {
    AMFFLogo,
    MenuIcon,
    MobileAsideHeader,
    AnchorLink,
  },
  setup(props, context) {
    const isOpen = ref(false);

    function open() {
      isOpen.value = true;
      //To prevent scroll on background layout when MovileAside header is open
      document.querySelector("#app-component")?.classList.add("fixed");
      document.querySelector("#app-component")?.classList.remove("relative");
    }

    function close() {
      isOpen.value = false;
      document.querySelector("#app-component")?.classList.remove("fixed");
      document.querySelector("#app-component")?.classList.add("relative");
    }

    const { y } = useWindowScroll();

    const header = ref<HTMLElement | null>(null);
    const headerHeight = useElementHeight(header);
    const hideHeader = ref(false);
    watchEffect(() => {
      context.emit("update:headerHeight", headerHeight.value);
    });

    onMounted(() => {
      var prevScrollpos = window.pageYOffset;
      window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;

        if (currentScrollPos <= 0) {
          hideHeader.value = false;
          // ignore safari iOS "bounce" scroll
          return;
        }

        if (prevScrollpos > currentScrollPos) {
          hideHeader.value = false;
        } else {
          hideHeader.value = true;
        }
        prevScrollpos = currentScrollPos;
      };
    });

    return {
      isOpen,
      open,
      close,
      y,
      header,
      hideHeader,
    };
  },
});
