
import { defineComponent } from "vue";
import AnchorLink from "@/components/AnchorLink.vue";
import AMFFLogo from "@/components/AMFFLogo.vue";
import { useHeaderLinks } from "@/composables/useHeaderLinks";

export default defineComponent({
  components: { AnchorLink, AMFFLogo },
  setup() {
    const { items, expandItem } = useHeaderLinks();

    return { items, expandItem };
  },
});
