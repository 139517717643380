import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "\n      max-w-container\n      flex\n      items-center\n      py-6\n      px-container\n      border-b border-amff-gray border-opacity-25\n    " }
const _hoisted_2 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AMFFLogo = _resolveComponent("AMFFLogo")
  const _component_AnchorLink = _resolveComponent("AnchorLink")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_AnchorLink, {
        to: { name: 'home' },
        class: "mr-auto",
        style: {"cursor":"pointer"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AMFFLogo, {
            height: "4.0rem",
            isWhite: ""
          })
        ]),
        _: 1
      })
    ])
  ]))
}