export default function getUserPreferredLang(): string {
  // Find out if user has set it's prefered language as "es" or "cat" ("ca")
  const preferedLanguage = navigator.languages.find((lang) => {
    if (lang.startsWith("es")) return true;
    if (lang.startsWith("ca")) return true;
    return false;
  });

  // use user preference as default language, fallback to whatever we have in ENV
  const locale = preferedLanguage
    ? preferedLanguage.slice(0, 2).replace(/^ca$/, "cat") // convert lang from ISO to our ad-hoc nomenclature
    : process.env.VUE_APP_I18N_LOCALE;

  return locale;
}
