import { ref, Ref } from "vue";
import { useResizeObserver } from "@vueuse/core";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useElementHeight = (element: Ref<HTMLElement | null>) => {
  const elementHeight = ref<number | null>(
    element.value ? element.value.clientHeight : null
  );

  useResizeObserver(element, (entries) => {
    const entry = entries[0];
    const { height } = entry.contentRect;
    elementHeight.value = height;
  });

  return elementHeight;
};
