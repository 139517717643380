import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList([
        { url: _ctx.tiktokURL, icon: _ctx.TikTokIcon },
        { url: _ctx.twitterURL, icon: _ctx.TwitterIcon },
        { url: _ctx.youtubeURL, icon: _ctx.YoutubeIcon },
        { url: _ctx.instagramURL, icon: _ctx.InstagramIcon },
        { url: _ctx.facebookURL, icon: _ctx.FacebookIcon },
      ], (link) => {
      return (_openBlock(), _createBlock("a", {
        key: link.url,
        href: link.url,
        target: "_blank",
        rel: "noopener noreferrer",
        class: "lg:transform lg:hover:scale-150 transition-transform",
        style: {"padding":"0 0.75em"}
      }, [
        _createVNode("img", {
          alt: "rrss_image",
          src: link.icon,
          width: "24",
          height: "24"
        }, null, 8, ["src"])
      ], 8, ["href"]))
    }), 128))
  ]))
}