<template>
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M1 8.8L3.75 6 12 14.4 20.25 6 23 8.8l-8.25 8.399v.001L12 20v-.002V20l-2.75-2.8L1 8.8z"
      fill-rule="nonzero"
    />
  </svg>
</template>
