
import { defineComponent, ref, watchEffect, watch } from "vue";

import { useElementHeight } from "@/composables/useElementHeight";
import FirstRow from "@/components/header/desktop-header/FirstRow.vue";
import SecondRow from "@/components/header/desktop-header/SecondRow.vue";
import ThirdRow from "@/components/header/desktop-header/ThirdRow.vue";
import { useRoute } from "vue-router";
import { values } from "lodash";

export default defineComponent({
  props: {
    needBackground: { type: Boolean },
  },
  components: {
    FirstRow,
    SecondRow,
    ThirdRow,
  },
  setup(props, context) {
    const header = ref<HTMLElement | null>(null);
    const headerHeight = useElementHeight(header);

    const route = useRoute();
    const isBackgroundBlack = ref(false);

    watch(
      () => route.meta.isBackgroundBlack,
      (value) => {
        if (value === true) {
          isBackgroundBlack.value = true;
        }
        if (value === false) {
          isBackgroundBlack.value = false;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    watchEffect(() => {
      context.emit("update:headerHeight", headerHeight.value);
    });

    return {
      header,
      isBackgroundBlack,
    };
  },
});
