import { unref, computed } from "vue";
import { useRoute } from "vue-router";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRouteWithLang = () => {
  const route = useRoute();
  const currentLang = computed(() => route.params.lang as string);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildRouteWithLang = (to: any) => {
    return {
      ...to,
      params: { ...to.params, lang: unref(currentLang) },
    };
  };

  return {
    route,
    currentLang,
    buildRouteWithLang,
  };
};
