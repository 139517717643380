{
  "languages": {
    "es": "Español",
    "cat": "Català"
  },
  "short_languages": {
    "es": "Español",
    "cat": "Catalá"
  },
  "header": {
    "mallorca": "Mallorca",
    "mallorca_edition": "19.07 - 28.07 / Mallorca",
    "online_edition": "19.07 - 19.08 / filmin.es",
    "editions": "Edicions",
    "history": "Història",
    "team": "Equip",
    "who_we_are": "Qui som",
    "contact": "Contacte",
    "online": "Online",
    "international": "Internacional",
    "start": "Inici",
    "jobs": "Treballa a AMFF",
    "program": "Programa",
    "program_complete": "Programa complet",
    "movies": "Pel·lícules",
    "music": "Música",
    "talks": "Talks",
    "parrilla": "Graella",
    "professionals": "Professionals",
    "mallorca_talents_lab": "Mallorca Talents Lab",
    "talents_lab": "Talents lab",
    "partakers": "Convidats",
    "eurovod": "Eurovod",
    "screen_stars": "Screen Starts",
    "news": "Notícies",
    "press": "Premsa",
    "mallorca_an_island_of_movie": "Mallorca, Una illa de Pel·lícula",
    "encuentro_comica": "Trobada COMICA",
    "emerging_workshop": "Taller Emergents"
  },
  "footer": {
    "press_kit": "Kit de premsa",
    "contact": "Contacte",
    "sponsors": "Sponsors",
    "proyect_of": "Atlàntida Mallorca Film Fest és un projecte de"
  },
  "actions": {
    "share": "Compartir",
    "copy": "Copiar",
    "copied": "Copiat",
    "close": "Tancar",
    "more_details": "Més info"
  },
  "home": {
    "header_description": "14a edició",
    "header_slogan": "L'estiu que et canviarà la vida",
    "header_description2": "Una setmana a Mallorca, un mes a Filmin",
    "discover_music": "Reserva ja les teves entrades",
    "header_description3": "Fins al 31 de Maig presenta el teu projecte i aconsegueix que FILMIN participi a la coproducció.",
    "discover_this_years_content": "Més de 80 esdeveniments exclusius",
    "follow_festival_at": "Segueix el festival amb",
    "see_the_full_program": "Veure el programa complet",
    "play_trailer": "Veure tràiler",
    "watch_aftermovie": "Veure resum",
    "overview_movies": "Més de 100 estrenes exclusives, arriscades i provocadores. Des de les grans triomfadores de Berlín, Cannes i Sundance que aterren per primera vegada a Espanya, fins al debut del talent més trencador, passant per les ja mítiques seccions LGTBI+, Arts, Clàssics, Balear o Nins. Noè, Huppert, Sachs, Serra, Arana, Abramovic, Jordan, Martín Gimeno, Loznitsa, Padial, Villaronga i molts més.",
    "overview_music": "Les millors propostes emergents del panorama underground comparteixen escenari amb artistes i bandes consagrades per tancar les nits d'Atlàntida en gran. Alizzz, Christina Rosenvinge, Natalia Lacunza, Rusoswky, Putochinomaricón, Ladilla Russa o Rita Payés són algunes de les veus que conformen el so d'AMFF. Frescor, petardeo i electropop.",
    "overview_talks": "AMFF és cinema, però també debat, actualitat i política. Part de la programació anirà acompanyada de xerrades amb personalitats de primer nivell per a aprofundir i reflexionar sobre els temes abordats en les pel·lícules que precedeixen. Pablo Simón, Annie Ernaux, El Barroquista, Slavoj Žižek, Ana Pastor, Pol Guasch o C. Tangana han protagonitzat algunes xerrades sobre art, identitat, filosofia, memòria històrica, la crisi climàtica o les guerres del present.",
    "featured_events": "Imprescindibles d'aquesta edició",
    "sign_up_title_1": "Secció oficial",
    "sign_up_subtitle_1": "Inscriu-te fins al 16 de Maig",
    "sign_up_title_2": "Atlàntida Mallorca Talents Lab - WIP",
    "sign_up_subtitle_2": "Inscriu-te fins L'1 de Juny",
    "overview_talents_lab": "El punt de trobada de la indústria dins d'Atlàntida. A través de mentories amb alguns dels millors productors del país, assessorament sobre distribució i disseny d'audiències, i l'oportunitat de compartir el seu projecte davant agents clau de la indústria, AMTL impulsa el talent d'autors i productors novells que prometen ser les grans veus del futur del cinema nacional.",
    "overview_bono": "Acosegueix el teu passaport AMFF-Filmin a Cines Rivoli.",
    "sign_up_talent": "Inscriu-te al Mallorca Talents Lab",
    "sign_up_your_film": "Inscriu la teva pel·lícula en la Secció Oficial",
    "see_the_full_program_23": "Veure programació",
    "sign_up_film": "INSCRIU LA TEVA PEL·LÍCULA",
    "sub-button_text": "Un nou format per impulsar el teu projecte i acompanyar-te en la producció",
    "sub-button_text_2": "Envia la teva pel·lícula acabada i estrena-la al major festival híbrid del món"
  },
  "post": {
    "share_this_with_your_comunity": "Comparteix això amb la teva comunitat social",
    "or_copy_link": "o copiar enllaç",
    "lastest_news": "Últimes notícies",
    "see_latest_news": "Veure totes les notícies",
    "all_news": "Totes les notícies"
  },
  "error": {
    "are_you_lost": "T'has perdut?",
    "calm_down_if_youre_lost": "Ens passa a tots, no et preocupis. Potser has escrit malament l'adreça, o l'enllaç que buscaves ja no existeix.",
    "take_me_home": "Anar al inici",
    "no_events_in_filter": "No hi ha cap esdeveniment que coincideixa amb els filtres seleccionats.",
    "try_selecting_a_different_filter": "Prova seleccionant un altre dia o un altre tipus d'esdeveniment."
  },
  "coming_soon": {
    "stay_tuned": "Programació completa 1 de Juliol",
    "come_back": "Mil gràcies per la paciència i l'interés."
  },
  "events": {
    "download_schedule": "Programa de mà",
    "accredit_yourself": "Acredita’t",
    "industry": "Indústria",
    "press": "Premsa",
    "event_types": {
      "all": "Tot",
      "movies": "Movies",
      "movie": "Pel·lícula",
      "music": "Música",
      "talks": "Talks",
      "talk": "Talk"
    },
    "go_to_next_day": "Anar al dia següent",
    "day": "Dia",
    "location": "Localització",
    "section": "Secció",
    "clear_all": "Treure filtres",
    "buy_tickets": "Comprar entrades",
    "early_access" : "Entrades anticipades esgotades",
    "book_tickets": "Reservar entrades",
    "about_movie": "Sobre la pel·lícula",
    "the_same_day": "El mateix día",
    "listen_in_spotify": "Escoltar en Spotify",
    "original_title": "Títol original",
    "tags": "Temes",
    "staff": "Repartiment",
    "others_projection": "Altres pel·lícules",
    "others_conference": "Altres conferències",
    "others_concert": "Altres concerts",
    "full_capacity": "Aforament complet",
    "see_movie": "Veure pel·lícula",
    "view_all_movies": "Veure totes les pel·lícules",
    "coming_soon": "Properament"
  },
  "talents": "<p>Atlàntida Mallorca Film Fest, en la seva dotzena edició, torna a apostar per els joves talents del cine. Amb la col·laboració de Media i Europa Creativa Catalunya, llançem la cinquena convocatòria del Mallorca Talents Lab (“Talents Lab” en endevant), un espai que et permetrà desenvolupar el teu projecte de llargmetratge sota la lupa dels millors experts del sector cinematogràfic.</p>  <p>El  Talents  Lab  té  com  objectiu estrènyer llaços  entre  joves  creadors  i  professionals  consolidats  que tutoritzaràn  i  assessoraran  en  el  desenvolupament  dels  projectes  participants,  preparant-los per accedir a convocatòries internacionals i al seu pas per festivales.</p> <p> Aquest any el Talents Lab va més enllà de la ficció per apropar-se també al documental i incorporar la posibilitat d’aconseguir el suport de Comunitat Filmin, S.L. ( “Filmin”), mitjançant el finançament anticipat de la futura obra audiovisual, comptant amb el suport i seguiment del seu equip editorial durant un any.</p>",
  "international": {
    "countries": "Portugal. Grècia. Sèrbia. Bulgària. Romania. Albània.",
    "title": "Edició Internacional",
    "content": "La IV Edició del Festival online internacional que s’efectuarà a Portugal Serbia, Grecia, Rumania i Albania es celebrarà de l’1 de desembre al 31 de desembre de 2022. La programació es farà pública al novembre.",
    "here": "aqui"
  },
  "online": {
    "title": "Online edition",
    "description": "La programació completa del festival online que se celebrarà a Filmin s'anunciarà el dia 8 de juliol a les 12h."
  }
}
