
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import SocialLinks from "../SocialLinks.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import CheckIcon from "@/components/icons/CheckIcon.vue";
import { useHeaderLinks } from "@/composables/useHeaderLinks";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    SocialLinks,
    AnchorLink,
    CheckIcon,
    ChevronIcon,
  },
  props: {
    isBackgroundBlack: { type: Boolean },
  },
  setup() {
    const router = useRouter();
    const { currentLang, currentRouteSwitchingLangs, isCurrentLinkActive } =
      useHeaderLinks();
    const langDropdownOpen = ref(false);
    function switchLang(lang: string) {
      if (currentLang.value === lang) {
        // do nothing?
      } else {
        window.location.href = router.resolve(
          currentRouteSwitchingLangs.value
        )?.href;
      }
    }

    function closeDropdown() {
      langDropdownOpen.value = false;
    }

    onMounted(() => {
      window.addEventListener("click", closeDropdown, { capture: true });
    });

    onUnmounted(() => {
      window.removeEventListener("click", closeDropdown);
    });

    return { langDropdownOpen, currentLang, switchLang, isCurrentLinkActive };
  },
});
