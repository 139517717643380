import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import getUserPreferredLang from "@/utils/getUserPreferredLang";

const defaultLang = getUserPreferredLang();
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: `/${defaultLang}`,
  },
  {
    // Google indexed this url, and we can't control HTTP headers to send a 404 in a static site, so we are doing this instead
    path: "/bases2022.pdf",
    redirect: `/es/professionals/talents`,
  },
  {
    path: "/:lang",
    name: "root",
    component: () => import(/* webpackChunkName: "root" */ "./Root.vue"),
    children: [
      {
        path: "",
        name: "home",
        meta: { isBackgroundBlack: false },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/HomeSimple.vue"),
      },
      // {
      //   path: "online",
      //   name: "online",
      //   component: () =>
      //     import(/* webpackChunkName: "online" */ "../views/Online.vue"),
      // },
      // {
      //   path: "international",
      //   name: "international",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "international" */ "../views/International.vue"
      //     ),
      // },
      {
        path: "events/:type?",
        name: "events",
        meta: { isBackgroundBlack: true },
        component: () =>
          import(/* webpackChunkName: "events" */ "../views/Events.vue"),
        props: (route) => ({ type: route.params.type }),
      },
      {
        path: "events/:type/:slug",
        name: "event",
        meta: { isBackgroundBlack: false },
        component: () =>
          import(/* webpackChunkName: "event" */ "../views/Event.vue"),
        props: (route) => ({
          type: route.params.type,
          slug: route.params.slug,
        }),
      },
      {
        path: "schedule",
        name: "schedule",
        meta: { isBackgroundBlack: true },
        component: () =>
          import(/* webpackChunkName: "schedule" */ "../views/Schedule.vue"),
      },
      // {
      //   path: "professionals/talents",
      //   name: "professionals-talents",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "professionals-talents" */ "../views/Talents.vue"
      //     ),
      // },
      // {
      //   path: "mallorca-an-island-of-movie",
      //   name: "mallorca-an-island-of-movie",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "professionals-talents" */ "../views/MallorcaAnIslandOfMovie.vue"
      //     ),
      // },
      // {
      //   path: "emerging-workshop",
      //   name: "emerging-workshop",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "professionals-talents" */ "../views/EmerginWorkshop.vue"
      //     ),
      // },
      // {
      //   path: "green-film-lab",
      //   name: "green-film-lab",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "professionals-talents" */ "../views/GreenFilmLab.vue"
      //     ),
      // },
      // {
      //   path: "professionals/eurovod",
      //   name: "professionals-eurovod",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "professionals-eurovod" */ "../views/Eurovod.vue"
      //     ),
      // },
      // {
      //   path: "professionals/stars",
      //   name: "professionals-stars",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "professionals-stars" */ "../views/Stars.vue"
      //     ),
      // },
      // {
      //   path: "professionals/partakers",
      //   name: "professionals-partakers",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "professionals-partakers" */ "../views/Partakers.vue"
      //     ),
      // },
      {
        path: "news",
        name: "news",
        meta: { isBackgroundBlack: true },
        component: () =>
          import(/* webpackChunkName: "news" */ "../views/News.vue"),
      },
      {
        path: "news/:slug",
        name: "post",
        meta: { isBackgroundBlack: true },
        component: () =>
          import(/* webpackChunkName: "post" */ "../views/Post.vue"),
        props: (route) => ({
          slug: route.params.slug,
        }),
      },
      {
        path: "history",
        name: "history",
        meta: { isBackgroundBlack: true },
        component: () =>
          import(/* webpackChunkName: "history" */ "../views/History.vue"),
      },
      {
        path: "who-we-are",
        name: "who-we-are",
        meta: { isBackgroundBlack: true },
        component: () =>
          import(/* webpackChunkName: "history" */ "../views/whoWeAre.vue"),
      },
      // {
      //   path: "press",
      //   name: "press",
      //   component: () =>
      //     import(/* webpackChunkName: "press" */ "../views/Press.vue"),
      // },
      // {
      //   path: "bono",
      //   name: "bono",
      //   component: () =>
      //     import(/* webpackChunkName: "bono" */ "../views/Bono.vue"),
      // },
      {
        path: "/:catchAll(.*)*",
        name: "404",
        component: () =>
          import(/* webpackChunkName: "404" */ "../views/404.vue"),
      },
    ],
  },
];

const router = createRouter({
  history:
    process.env.VUE_APP_ENV === "gl-pages"
      ? createWebHashHistory()
      : createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path) {
      return; // don't change scroll if only query params change
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
