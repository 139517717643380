import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = {
  role: "menu",
  "aria-labelledby": "aria-label-mobile-header",
  class: "fixed inset-0 bg-amff-black-2024 z-40 overflow-y-auto"
}
const _hoisted_2 = { class: "\n        flex\n        py-4\n        px-container\n        items-center\n        border-b border-amff-gray border-opacity-25\n      " }
const _hoisted_3 = { class: "mt-auto p-4 pt-8 font-bold text-xl text-center" }
const _hoisted_4 = { class: "-mb-3" }
const _hoisted_5 = { class: "mt-8 whitespace-nowrap" }
const _hoisted_6 = {
  key: 0,
  class: "w-14 h-1 bg-amff-yellow-2024"
}
const _hoisted_7 = {
  key: 0,
  class: "w-14 h-1 bg-amff-yellow-2024"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AMFFLogo = _resolveComponent("AMFFLogo")
  const _component_CloseIcon = _resolveComponent("CloseIcon")
  const _component_ChevronIcon = _resolveComponent("ChevronIcon")
  const _component_AnchorLink = _resolveComponent("AnchorLink")
  const _component_SocialLinks = _resolveComponent("SocialLinks")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_AMFFLogo, {
        style: {"width":"9rem","height":"2rem"},
        isSmall: "",
        isWhite: "",
        class: "w-2/4 max-w-xs",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }),
      _createVNode("button", {
        class: "ml-auto px-2 text-amff-yellow-2024",
        type: "button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_CloseIcon, {
          class: "w-6 h-6",
          fill: "currentColor",
          "aria-label": "Cerrar / Tancar"
        })
      ])
    ]),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_Fragment, {
        key: item.text
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(!item.children ? _ctx.AnchorLink : 'button'), {
          to: item.to,
          href: item.href,
          type: "button",
          class: ["\n          flex\n          w-full\n          py-4\n          px-container\n          items-center\n          font-display\n          text-2xl\n          border-b border-amff-gray border-opacity-25\n        ", {
          'bg-amff-white text-amff-black-2024':
            item.isSelectedMobile || item.isExpandedMobile,
        }],
          "aria-expanded": 
          item.children?.length
            ? item.isSelectedMobile || item.isExpandedMobile
            : undefined
        ,
          "aria-controls": 
          item.children?.length ? `aria-childrensOf-${item.id}` : undefined
        ,
          onClick: ($event: any) => {
          _ctx.expandItem(item);
          !item.children ? this.$emit('close') : null;
        }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(item.text)) + " ", 1),
            (item.children?.length > 1 && item.id !== 'mallorca')
              ? (_openBlock(), _createBlock(_component_ChevronIcon, {
                  key: 0,
                  class: "ml-auto w-6 h-6",
                  fill: "currentColor",
                  style: 
            item.isExpandedMobile
              ? `transform: rotate(180deg)`
              : `transform: rotate(0deg)`
          
                }, null, 8, ["style"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to", "href", "class", "aria-expanded", "aria-controls", "onClick"])),
        _createVNode(_Transition, { name: "expand" }, {
          default: _withCtx(() => [
            (
            (item.isExpandedMobile &&
              item.children &&
              item.children.length > 1) ||
            item.id === 'mallorca'
          )
              ? (_openBlock(), _createBlock("div", {
                  key: 0,
                  id: `aria-childrensOf-${item.id}`
                }, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(item.children, (subitem) => {
                    return (_openBlock(), _createBlock("div", {
                      key: subitem.text
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(!subitem.children ? _ctx.AnchorLink : 'button'), {
                        to: subitem.to,
                        href: subitem.href,
                        type: "button",
                        class: ["\n                flex\n                w-full\n                py-4\n                px-container\n                items-center\n                text-xl\n                border-b border-amff-gray border-opacity-25\n                font-body font-bold\n              ", {
                'text-amff-white': subitem.isSelected || subitem.isExpanded,
              }],
                        "aria-expanded": 
                subitem.children?.length ? subitem.isExpanded : undefined
              ,
                        "aria-controls": 
                subitem.children?.length
                  ? `aria-subchildrensOf-${subitem.id}`
                  : undefined
              ,
                        onClick: ($event: any) => (
                _ctx.selectSecondLevelItem(
                  subitem.children?.length && subitem.isExpanded
                    ? null
                    : subitem
                )
              )
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(subitem.text)) + " ", 1),
                          (subitem.children?.length)
                            ? (_openBlock(), _createBlock(_component_ChevronIcon, {
                                key: 0,
                                class: "ml-auto w-6 h-6",
                                fill: "currentColor",
                                style: 
                  subitem.isExpanded
                    ? `transform: rotate(180deg)`
                    : `transform: rotate(0deg)`
                
                              }, null, 8, ["style"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["to", "href", "class", "aria-expanded", "aria-controls", "onClick"])),
                      _createVNode(_Transition, { name: "expand" }, {
                        default: _withCtx(() => [
                          (
                  subitem.children &&
                  subitem.children.length &&
                  subitem.isExpanded
                )
                            ? (_openBlock(), _createBlock("div", {
                                key: 0,
                                id: `aria-subchildrensOf-${subitem.id}`
                              }, [
                                (_openBlock(true), _createBlock(_Fragment, null, _renderList(subitem.children, (subsubitem) => {
                                  return (_openBlock(), _createBlock("div", {
                                    key: subsubitem.text
                                  }, [
                                    (subsubitem.href)
                                      ? (_openBlock(), _createBlock("a", {
                                          key: 0,
                                          href: subsubitem.href,
                                          target: "_blank",
                                          class: "block py-4 text-lg font-bold px-container"
                                        }, _toDisplayString(_ctx.$t(subsubitem.text)), 9, ["href"]))
                                      : (_openBlock(), _createBlock(_component_AnchorLink, {
                                          to: subsubitem.to,
                                          key: subsubitem.text,
                                          class: ["\n                      flex\n                      w-full\n                      py-4\n                      px-container\n                      items-center\n                      font-bold\n                      text-lg\n                      border-b border-amff-gray border-opacity-25\n                    ", { 'text-amff-black-2024': subsubitem.isSelected }],
                                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t(subsubitem.text)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["to", "class"]))
                                  ]))
                                }), 128))
                              ], 8, ["id"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ], 8, ["id"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ], 64))
    }), 128)),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_AnchorLink, {
          to: { name: 'history' },
          class: ["px-4 mb-3 inline-block", {
            'text-amff-yellow-2024': _ctx.isCurrentLinkActive('history'),
          }],
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("header.history")) + ". ", 1)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode("a", {
          href: "https://drive.google.com/drive/folders/1g9WoCKG7tTC6hdx6ciOPbzsZ_IL7jUKx?usp=sharing",
          target: "_blank",
          class: ["px-4 mb-3 inline-block", {
            'text-amff-black-2024': _ctx.isCurrentLinkActive('press'),
          }],
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close')))
        }, _toDisplayString(_ctx.$t("header.press")) + ". ", 3)
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("button", {
          type: "button",
          class: ["px-4 font-bold", {
            'text-amff-yellow-2024': _ctx.currentLang === 'es',
          }],
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.switchLang('es')))
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t("languages.es")) + " ", 1),
          (_ctx.currentLang === 'es')
            ? (_openBlock(), _createBlock("div", _hoisted_6))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode("button", {
          type: "button",
          class: ["px-4 font-bold", {
            'text-amff-yellow-2024': _ctx.currentLang === 'cat',
          }],
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.switchLang('cat')))
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t("languages.cat")) + " ", 1),
          (_ctx.currentLang === 'cat')
            ? (_openBlock(), _createBlock("div", _hoisted_7))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _createVNode(_component_SocialLinks, { class: "mt-8 text-2xl" })
    ])
  ]))
}