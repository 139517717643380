
import { defineComponent, onBeforeUnmount, onMounted } from "vue";
import AMFFLogo from "@/components/AMFFLogo.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import FilminLogo from "@/components/FilminLogo.vue";

import CloseIcon from "@/components/icons/CloseIcon.vue";
import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import SocialLinks from "./SocialLinks.vue";
import { useHeaderLinks } from "@/composables/useHeaderLinks";
import { RouteLocationRaw, useRouter } from "vue-router";

export default defineComponent({
  components: {
    AMFFLogo,
    CloseIcon,
    ChevronIcon,
    SocialLinks,
    AnchorLink,
    FilminLogo,
  },
  setup(_, context) {
    const {
      items,
      currentLang,
      currentRouteSwitchingLangs,
      expandItem,
      expandSubitem,
      isCurrentLinkActive,
    } = useHeaderLinks();

    const router = useRouter();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function selectSecondLevelItem(item: any) {
      if (item === null) {
        expandSubitem(null);
      } else if (!item.to || item.children?.length) {
        expandSubitem(item);
      } else if (item.to) {
        routerPush(item.to);
      }
    }

    function routerPush(to: RouteLocationRaw) {
      router.push(to);
      context.emit("close");
    }

    function switchLang(lang: string) {
      if (currentLang.value === lang) {
        // do nothing?
      } else {
        window.location.href = router.resolve(
          currentRouteSwitchingLangs.value
        )?.href;
      }
    }

    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onBeforeUnmount(() => {
      document.body.style.overflow = "";
    });

    return {
      items,
      currentLang,
      switchLang,
      selectSecondLevelItem,
      routerPush,
      AnchorLink,
      expandItem,
      isCurrentLinkActive,
    };
  },
});
