import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilminLogo = _resolveComponent("FilminLogo")

  return (_openBlock(), _createBlock("div", {
    class: "flex items-center",
    style: _ctx.isWhite ? `filter: invert(1)` : ''
  }, [
    _createVNode("img", {
      src: `${_ctx.baseURL}img/AMFFLogo24Corto.png`,
      class: _ctx.isMobile ? 'w-20' : 'w-32'
    }, null, 10, ["src"]),
    _createVNode(_component_FilminLogo, {
      class: "ml-6",
      style: {"width":"auto","height":"45px"}
    })
  ], 4))
}