
import { defineComponent } from "vue";
// @ts-expect-error error
import mallorcaLogo from "@/assets/mallorca-logo-w&b.png";
import FilminLogo from "@/components/FilminLogo.vue";
import { useMediaQuery } from "@vueuse/core";

export default defineComponent({
  components: {
    FilminLogo,
  },
  props: {
    withoutIcon: { type: Boolean },
    height: { type: String },
    isSmall: { type: Boolean },
    isWhite: { type: Boolean },
  },
  setup() {
    const isMobile = useMediaQuery("(max-width: 1023px)");

    return {
      mallorcaLogo,
      isMobile,
      baseURL: process.env.BASE_URL,
    };
  },
});
