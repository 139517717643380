
import { defineComponent, getCurrentInstance, ref, watch } from "vue";
import { useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup() {
    const route = useRoute();
    const app = getCurrentInstance();

    const headerHeight = ref(null);

    watch(
      () => route.params.lang,
      (value) => {
        if (app) {
          app.appContext.config.globalProperties.$i18n.locale = value;
        }
      }
    );

    return {
      headerHeight,
    };
  },
});
