
import { defineComponent } from "vue";
import { useMediaQuery } from "@vueuse/core";
import MobileHeader from "./header/MobileHeader.vue";
import DesktopHeader from "./header/DesktopHeader.vue";

export default defineComponent({
  components: { DesktopHeader, MobileHeader },
  setup() {
    const isMobile = useMediaQuery("(max-width: 1065px)");
    return {
      isMobile,
    };
  },
});
